import { createApp, h, provide } from 'vue';
import App from './App.vue';
import router from './router';
import stores from './stores';
import global from './plugins/global';
import AtUi from './plugins/aliftech-ui';
import { tokenDecryption } from './utils/filters';
import { setToken } from './services';
import Cookies from '@/plugins/cookies';
import i18n from './plugins/i18n';
import AtToast from '@/plugins/aliftech-ui/toast';
import centrifuge from '@/plugins/centrifuge';
import { registerModules } from '@/registerModules';
import yandexMetrika from '@/plugins/yandex-metrika';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { DefaultApolloClient } from '@vue/apollo-composable';
import VueShepherdPlugin from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';

import 'viewerjs/dist/viewer.css';
import './assets/scss/main.scss';

import mainModule from '@/modules/main';
import alifshopModule from '@/modules/alifshop';
import acquiringModule from '@/modules/acquiring';
import nasiyaModule from '@/modules/nasiya';
import adminModule from '@/modules/admin';
import { directiveCopy } from './directives/copy';
import { Money3Directive } from 'v-money3';
import { pandaClient } from './services/pandaClient';

import sentry from './plugins/sentry';

import 'nprogress';
import 'nprogress/nprogress.css';

registerModules({
  mainModule,
  acquiringModule,
  alifshopModule,
  nasiyaModule,
  adminModule,
});

export const app = createApp({
  setup() {
    provide(DefaultApolloClient, pandaClient);
  },
  render: () => h(App),
});

app
  .use(VueQueryPlugin)
  .use(Cookies)
  .use(AtToast)
  .use(stores)
  .use(router)
  .use(i18n)
  .use(AtUi)
  .use(global)
  .use(yandexMetrika)
  .use(sentry)
  .use(VueShepherdPlugin)
  .mount('#app');

app.config.globalProperties.$toast = AtToast;
app.provide('$toast', AtToast);
app.provide('$centrifuge', centrifuge);
app.provide('checkoutRedirect', process.env.VUE_APP_PANDA_CHECKOUT_REDIRECT_URL);
app.directive('copy', directiveCopy);
app.directive('money3', Money3Directive);

i18n.global.locale = app.config.globalProperties.$cookies.get('lang') || 'uz';

const token = app.config.globalProperties.$cookies.get('auth');
if (token) {
  try {
    setToken(tokenDecryption(token));
  } catch (e) {
    setToken('');
  }
}
